import React from "react";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { Section } from "../styles";

export default function AIStrategy() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img10 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="What is Artificial Intelligence (AI)?" />
              <p className="sub-text">
                Artificial Intelligence (AI) is a branch of Science which deals
                with helping machines find solutions to complex problems in a
                more human-like fashion.
              </p>
              <p className="sub-text">
                This generally involves borrowing characteristics from human
                intelligence, and applying them as algorithms in a computer
                friendly way.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div
          id="rs-about"
          className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70"
        >
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/Cx5aNwnZYDc"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="sec-title3">
                  <PrimaryHeading primaryText="Importance of Artificial intelligence (AI):" />

                  <p className="">
                    AI enables human capabilities of understanding, planning,
                    reasoning, communication and perception to be undertaken by
                    software increasingly effectively, efficiently and at low
                    cost.
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">A more or less flexible</p>
                    </li>
                    <li>
                      <p className="list-text">
                        Efficient approach can be taken depending on the
                        requirements established, which influences how
                        artificial the intelligent behavior appears
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Artificial intelligence (AI) is a wide-ranging branch of
                        computer science concerned with building smart machines
                        capable of performing tasks that typically require human
                        intelligence.{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="4 key AI concepts you need to understand and Face recognition." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Categorization</a>
                    </h2>
                    <p className="services-txt">
                      {" "}
                      The first step to building an AI solution is creating what
                      I call “design intent metrics,” which are used to
                      categorize the problem.
                    </p>
                    <p className="services-txt">
                      {" "}
                      Whether users are trying to build a system that can play
                      Jeopardy, help a doctor diagnose cancer, or help an IT
                      administrator diagnose wireless problems, users need to
                      define metrics that allow the problem to be broken into
                      smaller pieces. In wireless networking, for example, key
                      metrics are user connection time, throughput, coverage,
                      and roaming. In cancer diagnosis, key metrics are white
                      cell count, ethnic background, and X-ray scans.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Classification</a>
                    </h2>
                    <p className="services-txt">
                      Once users have the problem categorized into different
                      areas, the next step is to have classifiers for each
                      category that will point users in the direction of a
                      meaningful conclusion. For example, when training an AI
                      system to play Jeopardy, users must first classify a
                      question as being literal in nature or a play on words,
                      and then classify by time, person, thing, or place. In
                      wireless networking, once users know the category of a
                      problem (e.g. a pre- or post-connection problem), users
                      need to start classifying what is causing the problem:
                      association, authentication, dynamic host configuration
                      protocol (DHCP), or other wireless, wired, and device
                      factors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Machine learning</a>
                    </h2>
                    <p className="services-txt">
                      Now that the problem is divided into domain-specific
                      chunks of metadata, users are ready to feed this
                      information into the magical and powerful world of machine
                      learning. There are many machine learning algorithms and
                      techniques, with supervised machine learning using neural
                      networks (i.e. deep learning) now becoming one of the most
                      popular approaches. The concept of neural networks has
                      been around since 1949, and I built my first neural
                      network in the 1980s. <br />
                      But with the latest increases in compute and storage
                      capabilities, neural networks are now being trained to
                      solve a variety of real-world problems, from image
                      recognition and natural language processing to predicting
                      network performance. Other applications include anomaly
                      feature discovery, time series anomaly detection, and
                      event correlation for root cause analysis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Collaborative filtering</a>
                    </h2>
                    <p className="services-txt">
                      Most people experience collaborative filtering when they
                      pick a movie on Netflix or buy something from Amazon and
                      receive recommendations for other movies or items they
                      might like. Beyond recommenders, collaborative filtering
                      is also used to sort through large sets of data and put a
                      face on an AI solution.
                    </p>
                    <p className="services-txt">
                      This is where all the data collection and analysis is
                      turned into meaningful insight or action. Whether used in
                      a game show, or by a doctor, or by a network
                      administrator, collaborative filtering is the means to
                      providing answers with a high degree of confidence. It is
                      like a virtual assistant that helps solve complex
                      problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
