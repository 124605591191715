import React, { useState } from "react";
import { CareersWrapper } from "../styles";
import { CareersContainerStyles } from "./styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedJob, setExpandedJob] = useState(null);

  const toggleExpand = (jobId: any) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  const careersData = [
    {
      id: 1,
      designation: "Full Stack Developer",
      job_id: "S26585",
      job_postedon: "04-02-2024",
      description:
        "We're seeking an experienced Full Stack Developer to join our vibrant team. You'll lead the development of both front-end and back-end applications, ensuring seamless integration. Collaboration with our product and design teams will be key to delivering exceptional, user-friendly software solutions.",
      responsibilities: [
        "Create and manage online apps with the help of contemporary frameworks and technologies.",
        "Implement and validate functionalities across the entire web development stack.",
        "Collaborate with cross-functional teams to define, develop, and implement new features.",
        "Optimize applications for performance and scalability.",
        "Debugg and troubleshoot problems to make sure everything runs smoothly.",
      ],
      requiredSkills: [
        "Proficiency in front-end technologies: HTML5, CSS3, JavaScript, and modern frameworks (e.g., React, Angular, Vue.js).",
        "Strong experience in back-end development using Python, Java, and Node.js.",
        "Familiarity with relational and non-relational databases such as MongoDB and MySQL.",
        "Knowledge of server-side CSS preprocessors (e.g., Sass, Less) and version control systems like Git.",
        "Understanding of best practices for online security.",
        "Excellent communication and problem-solving abilities.",
        "Ability to function both individually and together in a hectic setting.",
      ],
    },
    {
      id: 2,
      designation: ".NET Developer",
      job_id: "S23558",
      job_postedon: "25-02-2024",
      description:
        "We are looking to add a talented.NET Developer to our team. As a .NET Developer, you'll be responsible for crafting and managing top-tier software solutions using the .NET framework. Working closely with our development team, you'll collaborate to deliver scalable, reliable applications that meet client needs.",
      responsibilities: [
        "Develop and oversee online and desktop applications using the .NET framework.",
        "Work together to design, develop, and implement new features in cross-functional teams.",
        "Write clean, efficient code following best practices.",
        "Test and debug applications for smooth, reliable performance.",
        "Participate in code reviews to ensure high-quality, consistent code.",
        "Stay updated on the latest technology and market trends in .NET development.",
      ],
      requiredSkills: [
        "Proficiency in C# and the .NET framework.",
        "Experience with ASP.NET MVC and/or ASP.NET Core.",
        "Strong understanding of object-oriented programming (OOP) principles.",
        "Knowledge of front-end technologies: HTML5, CSS3, JavaScript, and jQuery.",
        "Proficiency in SQL Server or similar relational database systems.",
        "Understanding of software development lifecycle (SDLC) methodologies.",
        "Excellent problem-solving and analytical skills.",
        "Capacity to operate both individually and cooperatively in a group environment.",
      ],
    },
    {
      id: 3,
      designation: "Java Developer",
      job_id: "S26845",
      job_postedon: "25-02-2024",
      description:
        "We would want to expand our team by hiring a qualified Java Developer. As a Java Developer, you'll craft and maintain top-notch software solutions using Java and related tech. Collaborate with our team to build scalable, reliable, and efficient applications.",
      responsibilities: [
        "Design, develop, and maintain Java-based web applications.",
        "Implement new features and enhancements.",
        "Work together with cross-functional teams to comprehend needs and provide answers.",
        "Write clean, efficient, and well-documented code.",
        "Test and debug applications for optimal performance.",
        "Keep up with the most recent developments in Java technology and market trends.",
      ],
      requiredSkills: [
        "Proficiency in Java.",
        "Experience with Spring Framework.",
        "Strong grasp of OOP principles.",
        "knowledge of web technologies including HTML, CSS, and JavaScript.",
        "Familiarity with relational databases.",
        "Experience with version control systems like Git.",
        "Excellent problem-solving skills.",
        "Ability to work independently and in a team.",
      ],
    },
    {
      id: 4,
      designation: "SAP Developer",
      job_id: "S25482",
      job_postedon: "06-02-2024",
      description:
        "We would want to expand our team by hiring a qualified SAP Developer. As a SAP Developer, you'll be responsible for designing, developing, and implementing custom solutions on the SAP platform. Collaborate with our team to deliver high-quality, scalable, and efficient solutions that meet our business requirements.",
      responsibilities: [
        "Design, develop, and implement custom solutions on the SAP platform using ABAP, Visualforce, and Lightning Web Components.",
        "Customize and configure SAP applications to meet business requirements.",
        "Integrate SAP with third-party systems using REST and SOAP APIs.",
        "Develop and maintain SAP workflows, triggers, and process builder flows.",
        "Collaborate with business stakeholders to gather requirements and design technical solutions.",
        "Test and debug SAP applications to ensure optimal performance and reliability.",
        "Provide technical support and troubleshooting assistance for SAP-related issues.",
        "Keep abreast on the most recent SAP innovations and industry best practices.",
      ],
      requiredSkills: [
        "Proficiency in ABAP programming language.",
        "Experience with SAP modules like Visualforce and Lightning Web Components.",
        "Understanding of SAP data model, security model, and sharing settings.",
        "Knowledge of SAP integration technologies such as REST and SOAP APIs.",
        "Familiarity with SAP development tools like SAP GUI and Eclipse.",
        "Strong problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 5,
      designation: "Sales Force Developer",
      job_id: "S23647",
      job_postedon: "12-02-2024",
      description:
        "We're on the lookout for a skilled Salesforce Developer to join our dynamic team. As a Salesforce Developer, you'll be tasked with crafting, developing, and deploying tailored solutions on the Salesforce platform. Join forces with our team to deliver top-notch, scalable, and efficient solutions aligned with our business needs.",
      responsibilities: [
        "Craft and implement custom solutions on Salesforce utilizing Apex, Visualforce, and Lightning Web Components.",
        "Tailor Salesforce apps to particular business requirements.",
        "REST and SOAP APIs can be used to integrate Salesforce with external systems.",
        "Develop and maintain Salesforce workflows, triggers, and process builder flows.",
        "Collaborate with stakeholders to gather requirements and devise technical solutions.",
        "Test and debug to make sure everything works as it should.",
        "Provide technical support and troubleshoot Salesforce-related issues.",
        "Keep abreast of the latest Salesforce technologies and practices.",
      ],
      requiredSkills: [
        "Proficient in Apex programming.",
        "Experienced with Salesforce modules such as Visualforce and Lightning Web Components.",
        "Understanding of Salesforce data and security models.",
        "Familiarity with Salesforce integration technologies like REST and SOAP APIs.",
        "Knowledgeable in Salesforce development tools like Salesforce DX and Workbench.",
        "Strong problem-solving and analytical abilities.",
      ],
    },
    {
      id: 6,
      designation: "Data Engineer",
      job_id: "S22658",
      job_postedon: "10-02-2024",
      description:
        "We're looking for a Data Engineer to join our dynamic team. As a Data Engineer, you'll play a crucial role in designing, building, and maintaining robust data pipelines and infrastructure. Join us in harnessing the power of data to drive business insights and innovation.",
      responsibilities: [
        "Design, build, and maintain scalable data pipelines and infrastructure.",
        "Develop and optimize data storage solutions, including data warehouses and data lakes.",
        "Collaborate with cross-functional teams to understand data requirements and deliver effective data solutions.",
        "Implement data quality and governance processes to ensure data accuracy and reliability.",
        "Stay updated with the latest technologies and best practices in data engineering.",
      ],
      requiredSkills: [
        "Ability to programme in languages like Java, Scala, or Python.",
        "Familiarity with big data technologies such as Spark, Hadoop, or Kafka.",
        "Strong familiarity with database systems, particularly NoSQL and SQL databases.",
        "Knowledge of cloud computing systems like Google Cloud Platform, Azure, or Amazon.",
        "Familiarity with cloud platforms and services (e.g., AWS, GCP, Azure).",
        "Experience with data pipeline orchestration tools like Apache Airflow or Apache NiFi.",
        "Understanding of data modeling and schema design concepts.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 7,
      designation: "RPA Developer",
      job_id: "S26548",
      job_postedon: "15-03-2024",
      description:
        "We are looking for a talented RPA Developer to join our team. As an RPA Developer, you will be responsible for designing, developing, and implementing robotic process automation solutions to streamline business processes. You will work closely with stakeholders to understand requirements and deliver efficient automation solutions.",
      responsibilities: [
        "Design, develop, and implement robotic process automation solutions using RPA tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Collaborate with business analysts and stakeholders to gather requirements and define automation workflows.",
        "Develop and maintain bots to automate repetitive tasks and business processes.",
        "Test and debug automation solutions to ensure they meet quality standards and business requirements.",
        "Provide technical support and troubleshooting for deployed bots.",
        "Stay up-to-date with the latest RPA technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in RPA development tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Experience with programming languages such as C#, Java, or Python.",
        "Strong problem-solving and analytical skills.",
        "Ability to understand and document business processes.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 8,
      designation: "Data Analyst",
      job_id: "S27698",
      job_postedon: "18-03-2024",
      description:
        "We are seeking a Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting data to inform business decisions. You will work closely with stakeholders to identify data needs and provide insights that drive strategic initiatives.",
      responsibilities: [
        "Collect, clean, and preprocess data from various sources for analysis.",
        "Perform exploratory data analysis to uncover patterns, trends, and insights.",
        "Develop and maintain dashboards and reports to visualize and communicate key metrics and findings.",
        "Collaborate with stakeholders to understand business requirements and provide data-driven recommendations.",
        "Conduct statistical analysis and modeling to support decision-making processes.",
        "Stay up-to-date with data analysis techniques and tools.",
      ],
      requiredSkills: [
        "Proficiency in data analysis tools and programming languages such as Python, R, SQL, or Excel.",
        "Experience with data visualization tools such as Tableau, Power BI, or Google Data Studio.",
        "Strong analytical and problem-solving skills.",
        "Ability to communicate complex findings and insights to non-technical stakeholders.",
        "Knowledge of statistical analysis and modeling techniques.",
        "Attention to detail and ability to work with large datasets.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 9,
      designation: "BI Developer",
      job_id: "S28746",
      job_postedon: "20-03-2024",
      description:
        "We are looking for a BI Developer to join our team. As a BI Developer, you will be responsible for designing, developing, and maintaining business intelligence solutions. You will work closely with stakeholders to gather requirements and deliver data-driven insights that support strategic decision-making.",
      responsibilities: [
        "Design, develop, and maintain BI solutions, including data models, dashboards, and reports.",
        "Collaborate with business stakeholders to gather requirements and define KPIs.",
        "Perform data profiling and analysis to understand data quality and identify opportunities for improvement.",
        "Optimize SQL queries and data pipelines for performance and efficiency.",
        "Develop and maintain ETL processes to extract, transform, and load data from various sources.",
        "Stay up-to-date with BI tools and technologies.",
      ],
      requiredSkills: [
        "Proficiency in SQL and experience with relational databases such as SQL Server, PostgreSQL, or Oracle.",
        "Experience with BI tools such as Microsoft Power BI, Tableau, or QlikView.",
        "Strong analytical and problem-solving skills.",
        "Ability to work with stakeholders to understand business requirements.",
        "Knowledge of data warehousing concepts and techniques.",
        "Experience with ETL tools and processes.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 10,
      designation: "QA Engineer",
      job_id: "S29854",
      job_postedon: "22-03-2024",
      description:
        "We are seeking a QA Engineer to join our team. As a QA Engineer, you will be responsible for ensuring the quality of our software products through manual and automated testing. You will work closely with development teams to identify and resolve issues and ensure that our products meet quality standards.",
      responsibilities: [
        "Develop and execute test plans, test cases, and test scripts to validate software functionality.",
        "Perform manual testing to identify bugs, defects, and usability issues.",
        "Develop and maintain automated test scripts and frameworks to improve testing efficiency.",
        "Collaborate with development teams to reproduce, debug, and resolve issues.",
        "Conduct regression testing to ensure that software changes do not adversely affect existing functionality.",
        "Stay up-to-date with QA tools, techniques, and best practices.",
      ],
      requiredSkills: [
        "Experience with software testing methodologies, tools, and techniques.",
        "Proficiency in test automation tools such as Selenium, Cypress, or TestComplete.",
        "Strong analytical and problem-solving skills.",
        "Ability to work with cross-functional teams to identify and resolve issues.",
        "Knowledge of software development lifecycle (SDLC) processes.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 11,
      designation: "Software Engineer",
      job_id: "S30962",
      job_postedon: "24-03-2024",
      description:
        "We are looking for a Software Engineer to join our team. As a Software Engineer, you will be responsible for designing, developing, and maintaining software solutions. You will work closely with stakeholders to gather requirements and deliver high-quality software products that meet customer needs.",
      responsibilities: [
        "Design, develop, and maintain software applications and systems.",
        "Collaborate with stakeholders to gather requirements and define project scope.",
        "Write clean, efficient, and maintainable code following best practices.",
        "Test and debug software applications to ensure they meet quality standards.",
        "Conduct code reviews to provide feedback and ensure code quality.",
        "Stay up-to-date with software development tools, technologies, and best practices.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Java, Python, C#, or JavaScript.",
        "Experience with software development frameworks and libraries.",
        "Strong problem-solving and analytical skills.",
        "Ability to work with stakeholders to understand and prioritize requirements.",
        "Knowledge of software development lifecycle (SDLC) processes.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 12,
      designation: "Product Manager",
      job_id: "S31578",
      job_postedon: "28-03-2024",
      description:
        "We are seeking a Product Manager to join our team. As a Product Manager, you will be responsible for defining and driving the product vision, strategy, and roadmap. You will work closely with cross-functional teams to deliver innovative products that meet customer needs and drive business growth.",
      responsibilities: [
        "Define and prioritize product features and enhancements based on customer feedback, market research, and business objectives.",
        "Develop and maintain the product roadmap and communicate it to stakeholders.",
        "Work closely with engineering, design, and marketing teams to ensure timely delivery of high-quality products.",
        "Gather and analyze user feedback and data to make informed product decisions.",
        "Monitor and analyze product metrics and KPIs to track performance and identify areas for improvement.",
        "Stay up-to-date with industry trends and competitors to inform product strategy and positioning.",
      ],
      requiredSkills: [
        "Experience in product management roles, preferably in technology companies.",
        "Strong analytical and strategic thinking skills.",
        "Ability to translate customer needs and business requirements into product features.",
        "Excellent communication and collaboration skills.",
        "Experience working in agile development environments.",
        "Knowledge of product management tools and techniques.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
  ];

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filteredCareersData = careersData.filter((item) =>
    item.designation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="container">
        <CareersContainerStyles>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="item--sub-title">Careers</h2>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Search jobs..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control"
              />
            </div>
          </div>
          <CareersWrapper>
            <div className="container custom">
              <div className="row">
                {filteredCareersData.length === 0 ? (
                  <div className="col-lg-12 mt-5 mb-5">
                    <h4>No Results Found</h4>
                  </div>
                ) : (
                  filteredCareersData.map((item) => {
                    return (
                      <div className="col-lg-12 mb-30" key={item.id}>
                        <div className="rs-addon-number">
                          <div className="number-part">
                            <div className="number-title d-flex align-items-center justify-content-between">
                              <h3 className="title">{item.designation}</h3>
                              <i
                                className={`fa fa-chevron-${
                                  expandedJob === item.id ? "up" : "down"
                                } fa-2x`}
                                onClick={() => toggleExpand(item.id)}
                              ></i>
                            </div>
                            <div className="number-txt">
                              <div className="loac-text mb-1 pb-0">
                                <strong>JOB ID:</strong> {item.job_id}
                              </div>
                              <div className="loac-text">
                                <strong>Job Posted On:</strong>{" "}
                                {item.job_postedon}
                              </div>
                              <p>{item.description}</p>
                              {expandedJob === item.id && (
                                <div>
                                  <section>
                                    <h4 className="mt-5">Responsibilities:</h4>
                                    <ul>
                                      {item.responsibilities.map(
                                        (responsibility, index) => (
                                          <li key={index}>{responsibility}</li>
                                        )
                                      )}
                                    </ul>
                                  </section>
                                  <section>
                                    <h4>Required Skills:</h4>
                                    <ul>
                                      {item.requiredSkills.map(
                                        (skill, index) => (
                                          <li key={index}>{skill}</li>
                                        )
                                      )}
                                    </ul>
                                  </section>
                                </div>
                              )}
                            </div>
                            <div className="btn-part">
                              <Link
                                to="/RegistrationForm"
                                className="readon apply"
                              >
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </CareersWrapper>
        </CareersContainerStyles>
      </div>
    </div>
  );
}
