import React, { FunctionComponent } from "react";
import { FooterSection, CopyrightBar } from "./styles";

import Map from "../../../assets/images/map.svg";
import Mail from "../../../assets/images/mail.svg";
import Phone from "../../../assets/images/phone.svg";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/logo.svg";

const Footer: FunctionComponent = () => {
  return (
    <>
      <FooterSection>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-3">
              {/* <img src={logoImg} alt="logo" className={"logo"} /> */}
              <h5>Sbas IT Systems Inc</h5>
              <p className="pt-3">
                Sbas IT Systems Inc is a leading IT solutions company that
                excels in delivering AI and ML services. With an experience of 5
                years, we aim to deliver prime solutions to our customers.
              </p>
            </div>
            <div className="col-12 col-sm-3 ps-sm-5">
              <h5>Technologies</h5>
              <ul>
                <Link to="BigData">
                  <li>Bigdata/Hadoop</li>
                </Link>
                <Link to="/DataScience">
                  <li>Data Sceience</li>
                </Link>
                <Link to="/MachineLearning">
                  <li>Machine Learning</li>
                </Link>
                <Link to="/AIStrategy">
                  <li>AI Strategy</li>
                </Link>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Cloud</h5>
              <ul>
                <Link to="AWS">
                  <li>AWS</li>
                </Link>
                <Link to="/MicrosoftAzure">
                  <li>Microsoft Azure</li>
                </Link>
                <Link to="/Snowflake">
                  <li>Snowflake</li>
                </Link>
                <Link to="/Dataiku">
                  <li>Dataiku</li>
                </Link>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Contact</h5>
              <ul>
                <li>
                  <img src={Map} className="pe-3" />
                  1910 Pacific Ave, SUITE: 11600, Dallas, TX. 75201{" "}
                </li>
                <li>
                  {" "}
                  <img src={Mail} className="pe-3" />
                  info@sbasit.com
                </li>
                <li>
                  {" "}
                  <img src={Phone} className="pe-3" />
                  +1(945)308-8959
                </li>
              </ul>
            </div>
            {/* <div className="col-12 col-sm-2">
              <h5>Follow us</h5>
              <ul>
                <li>
                  <img src={FB} />
                </li>
                <li>
                  <img src={Instagram} />
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </FooterSection>

      <CopyrightBar>
        <p>
          Sbas IT Systems Inc Copyright © 2023. All rights reserved. Developed
          by NEWDEVEX Solutions Pvt, Ltd,
        </p>
      </CopyrightBar>
    </>
  );
};

export default Footer;
