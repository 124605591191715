import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import BG from "../../assets/images/8.svg";
import Img9 from "../../assets/images/9.svg";

export default function AboutUs() {
  return (
    <div>
      <BgHeading>
        <img src={BG} alt="" />
        <h5>About Sbas IT Systems Inc Inc.</h5>
        <div className="container">
          <Section className="about-main-content">
            <p>
              Sbas IT Systems Inc Is A Leading IT Solutions Company That Excels
              In Delivering AI And ML Services. With An Experience Of 5 years,
              We Aim To Deliver Prime Solutions To Our Customers. We Are Here To
              Help Our Customers In Every Possible Way. This Is Why We Are
              Trusted By Leading Companies Of Different Sectors.
            </p>
            <div className="row pt-5">
              <div className="col-12 col-sm-7">
                <Primaryheading
                  primaryText="We help to implement your ideas into automation"
                  secondaryText=""
                />
                <p>
                  Match The Fast-Changing World Of Technology By Employing AI
                  And Machine Learning Tools. Data-Driven Techniques Are The
                  Future Of Business Optimization. Make Sure That Your
                  Organisation Embraces This Change And Makes The Most Of It.
                </p>
                <p>
                  If you are also looking for a team to help you with
                  data-driven decision making processes to enhance your customer
                  base, you have landed on the right page. We prefer quality
                  over quantity and that is what makes Sbas IT Systems Inc one
                  of the bests in the market.
                </p>
                <p>
                  Our commitment is to assist our customers in every conceivable
                  manner, which is why we have earned the trust of prominent
                  companies across various industries.
                </p>
                <p>
                  We upgrade our tools as per the changing technology. This
                  allows us to stay updated with our client needs as well. Our
                  team assists you in efficiently managing and using data for
                  outstanding business growth.{" "}
                </p>
                <p>
                  We start from scratch and help you build effective cloud
                  platforms. Our data engineers, architects, and strategists
                  work step-by-step for 100% positive results.
                </p>
                <p>
                  Whether you are looking for IoT data employment, Data
                  Engineers, or AI Strategists; Sbas IT Systems Inc can arrange
                  them for you. We are ready to take on the challenges for our
                  clients
                </p>
              </div>
              <div className="col-12 col-sm-5">
                <img src={Img9} alt="" />
              </div>
            </div>
          </Section>
          <Section>
            <div className="row">
              <Primaryheading primaryText="Why Choose Us" secondaryText="" />
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Experienced Team</h2>
                  <p>
                    Our team of experts has extensive experience in delivering
                    IT solutions to businesses of all sizes.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Tailored Solutions</h2>
                  <p>
                    We work closely with you to develop customised solutions
                    that meet your specific business needs
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Proven Track Record</h2>
                  <p>
                    We have a proven track record of delivering successful IT
                    projects for our clients.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2> Ongoing Support</h2>
                  <p>
                    We provide ongoing support and maintenance to ensure that
                    your IT systems continue to operate smoothly.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Competitive Pricing</h2>
                  <p>
                    We offer Our services that are priced competitively,
                    ensuring affordability without compromising on the quality
                    we deliver.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </BgHeading>
    </div>
  );
}
