import React, { FunctionComponent, useState } from "react";
import { GridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import S1 from "../../../assets/images/s1.svg";
import S2 from "../../../assets/images/s2.svg";
import S3 from "../../../assets/images/s3.svg";
import S4 from "../../../assets/images/s4.svg";
import S5 from "../../../assets/images/s5.svg";
import S6 from "../../../assets/images/s6.svg";
import S7 from "../../../assets/images/s7.svg";
import S8 from "../../../assets/images/s8.svg";

const FourGrid: FunctionComponent = (props: any) => {
  const services = [
    {
      title: "Engineering Data",
      src: S1,
    },
    {
      title: "Cloud Strategy",
      src: S2,
    },
    {
      title: "Service for App Development",
      src: S3,
    },
    {
      title: "Monitoring, reporting, and visualisation",
      src: S4,
    },
    {
      title: "Services for Cloud Migration",
      src: S5,
    },
    {
      title: "Management Services",
      src: S6,
    },
    {
      title: "Artifical Intelligence and Knowledge Engineering",
      src: S7,
    },
    {
      title: "Management of Recovery from Emergency Services",
      src: S8,
    },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="OUR"
        secondaryText="Most Prominent Services"
        tagline="Sbas IT Systems Inc provides end-to-end data and tool learning analytics services."
      />
      <section>
        <div className="row">
          {services.map((item: any) => {
            return (
              <div className={"col-6 col-sm-6 col-md-3"}>
                <GridBox>
                  <img src={item.src} />
                  <h5>{item.title}</h5>
                </GridBox>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default FourGrid;
