import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { Link } from "react-router-dom";
import Img10 from "../../assets/images/10.svg";
import T1 from "../../assets/images/t1.png";
import T2 from "../../assets/images/t2.png";
import T3 from "../../assets/images/t3.png";
import T4 from "../../assets/images/t4.png";
import T5 from "../../assets/images/t5.png";
import T6 from "../../assets/images/t6.png";
import T7 from "../../assets/images/t7.png";
import T8 from "../../assets/images/t8.png";

export default function Technologies() {
  return (
    <BgHeading>
      <Section className="about-main-content bg-gray">
        <div className="container ">
          <div className="row pt-0 pt-sm-0">
            <div className="col-12 col-sm-7">
              <Primaryheading
                primaryText="Web Technologies We Rely On"
                secondaryText=""
              />
              <p>
                We have a stronger understanding of the latest web technologies
                and digital trends to implement it better for your brand new Web
                Application Development. Our developers are committed to
                excellent web design and development for your success.
              </p>
              <p>
                To rank as the top web development company we depend on the set
                of industry-standard web tools and technologies mentioned as
                follows:
              </p>
              <p>
                Deeply ingrained in your surroundings Self-contained and deeply
                integrated into your enterprise environment. Flexible in scale
                and pricing to meet your specific needs. And, unless you specify
                otherwise, always private.
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img10} alt="" />
            </div>
          </div>
        </div>
      </Section>
      <div className="container ">
        <Section>
          <Primaryheading primaryText="Cloud Services" secondaryText="" />
          <div className="row">
            <div className={"col-6 col-sm-3"}>
              <Link to="/Aws">
                <GridBox>
                  <img src={T1} alt="" />
                  <b>AWS</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/MicrosoftAzure">
                <GridBox>
                  <img src={T2} alt="" /> <b>Microsoft Azure</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/Snowflake">
                <GridBox>
                  <img src={T3} alt="" /> <b>Snowflake</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/Dataiku">
                <GridBox>
                  <img src={T4} alt="" /> <b>Dataiku</b>
                </GridBox>
              </Link>
            </div>
          </div>
        </Section>
        <Section>
          <Primaryheading primaryText="Programming" secondaryText="" />
          <div className="row">
            <div className={"col-6 col-sm-3"}>
              <Link to="/BigData">
                <GridBox>
                  <img src={T6} alt="" /> <b>Big Data & Hadoop</b>
                </GridBox>
              </Link>
            </div>

            <div className={"col-6 col-sm-3"}>
              <Link to="/DataScience">
                <GridBox>
                  <img src={T7} alt="" /> <b>Data Science</b>
                </GridBox>
              </Link>
            </div>

            {/* <div className={"col-6 col-sm-3"}>
              <Link to="/SQLMorph">
                <GridBox>
                  <img src={T1} alt="" /> <b>SQL Morph</b>
                </GridBox>
              </Link>
            </div> */}
            <div className={"col-6 col-sm-3"}>
              <Link to="/MachineLearning">
                <GridBox>
                  <img src={T8} alt="" /> <b>Machine learning</b>
                </GridBox>
              </Link>
            </div>

            <div className={"col-6 col-sm-3"}>
              <Link to="/AIStrategy">
                <GridBox>
                  <img src={T1} alt="" /> <b>AI Strategy</b>
                </GridBox>
              </Link>
            </div>
            {/* <div className={"col-6 col-sm-3"}>
              <Link to="/DataStrategy">
                <GridBox>
                  <img src={T1} alt="" />
                  <b>Data Strategy</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/DataEngineering">
                <GridBox>
                  <img src={T1} alt="" /> <b>Data Engineering</b>
                </GridBox>
              </Link>
            </div> */}
          </div>
        </Section>
      </div>
    </BgHeading>
  );
}
