import React, { useState } from "react";
import { FormWrapper } from "../styles";

export default function RegistrationForm() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    Profile: "",
    JobTitle: "",
    Name: "",
    Email: "",
    Mobile: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container mt-5 mb-5 d-flex justify-content-center">
      <FormWrapper>
        <h2>Application Submission</h2>
        <form
          id="ttm-contactform"
          action="https://formspree.io/f/mpzvrbnr"
          method="POST"
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="form-group col-12">
              Upload Resume
              <label style={{ marginTop: 6 }}>
                <span className="text-input">
                  <input
                    name="Profile"
                    type="file"
                    required
                    style={{ border: "transparent" }}
                  />
                </span>
              </label>
            </div>
            <div className="form-group col-12">
              <label style={{ marginBottom: "0.1rem" }}>Job Title</label>
              <select
                name="JobTitle"
                value={formData.JobTitle}
                onChange={handleChange}
                required
              >
                <option value="">Select Job Title</option>
                <option value="Full Stack Developer">
                  Full Stack Developer
                </option>
                <option value=".NET Developer">.NET Developer</option>
                <option value="Java Developer">Java Developer</option>
                <option value="SAP">SAP</option>
                <option value="Sales Force Developer">
                  Sales Force Developer
                </option>
                <option value="Data Engineer">Data Engineer</option>
                <option value="RPA Developer">RPA Developer</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="BI Developer">BI Developer</option>
                <option value="QA Engineer">QA Engineer</option>
                <option value="Software Engineer">Software Engineer</option>
                <option value="Product Manager">Product Manager</option>
                <option value="Service Now Developer">
                  Service Now Developer
                </option>
                <option value="SQL Developer">SQL Developer</option>
                <option value="DevOps Developer">DevOps Developer</option>
              </select>
            </div>
            <div className="form-group col-12">
              <label>
                Full Name
                <span className="text-input">
                  <input
                    name="Name"
                    type="text"
                    value={formData.Name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />
                </span>
              </label>
            </div>
            <div className="form-group col-12">
              Email Address
              <label>
                <span className="text-input">
                  <input
                    name="Email"
                    type="email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                  />
                </span>
              </label>
            </div>
            <div className="form-group col-12">
              Mobile Number
              <label>
                <span className="text-input">
                  <input
                    name="Mobile"
                    type="text"
                    value={formData.Mobile}
                    onChange={handleChange}
                    placeholder="Your Phone"
                    required
                  />
                </span>
              </label>
            </div>
          </div>
          {loading ? (
            <div className="loader"></div>
          ) : success ? (
            <p>Thanks for applying job!</p>
          ) : (
            <button
              type="submit"
              id="submit-btn"
              className="btn btn-success w-auto mb-5 mt-3 p-3"
            >
              SUBMIT APPLICATION
            </button>
          )}
        </form>
      </FormWrapper>
    </div>
  );
}
