import React, { useEffect, useState, FunctionComponent } from "react";
import { HeaderWrapper, MenuList } from "./styles";
import Router from "../../routes";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import logoImg from "../../../assets/images/logo.svg";

const Button = loadable(() => import("../../DesignSystem/Button"));

const Header: FunctionComponent = () => {
  return (
    <>
      <HeaderWrapper>
        <nav className="navbar navbar-expand-lg w-100 container">
          <div className="container-fluid d-flex justify-content-between">
            <Link to="/Home">
              {/* <img src={logoImg} alt="logo" className={"logo"} /> */}
              <h2 className={"logo"}>Sbas IT Systems Inc</h2>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarScroll"
            >
              <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <h5>Better Service</h5>
                        <li>
                          <Link to="/EmpowerDevelopment">
                            Empower development
                          </Link>
                        </li>
                        <p>join our expert team</p>
                      </ul>
                      <ul className="right">
                        <h5>Connections</h5>
                        {/* <li>
                          <Link to="">Overview</Link>
                        </li>
                        <p>An overview of customers using by industry</p> */}

                        <li>
                          <Link to="/Contact">Questions?</Link>
                        </li>
                        <p>Have questions? Get in touch.</p>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to="/Technologies" className="nav-item">
                    Technologies
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        {/* <h5>Better Service</h5> */}
                        <li>
                          <Link to="/AboutUs">About Us</Link>
                        </li>
                        <p>
                          Sbas IT Systems Inc Is A Leading IT Solutions Company
                        </p>
                        {/* <li>
                          <Link to="/Careers">Careers</Link>
                        </li>
                        <p>join our expanding staff</p> */}
                      </ul>
                      <ul className="right">
                        {/* <h5>Better Service</h5> */}
                        <li>
                          <Link to="/Clients">Clients</Link>
                        </li>
                        <p>An industry-specific breakdown of Sonar users</p>
                      </ul>
                    </div>
                  </div>
                </li>

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Solutions
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <h5>Better Service</h5>
                        <li>About Us</li>
                        <li>Careers</li>
                        <li>Customers</li>
                        <li>Connect with us</li>
                      </ul>
                      <ul className="right">
                        <h5>Media</h5>
                        <li>About Us</li>
                        <li>Careers</li>
                        <li>Customers</li>
                        <li>Connect with us</li>
                      </ul>
                    </div>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Knowledge
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/CaseStudies">Case Studies</Link>
                        </li>
                        <p>Discover the case studies.</p>
                      </ul>
                      <ul className="right">
                        {/* <li>
                          <Link to="/Ebooks">E-Books</Link>
                        </li> */}
                        <li>
                          <Link to="/Videos">Videos</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to="/Careers" className="nav-item">
                    Careers
                  </Link>
                </li>
              </MenuList>
              <Link to="/Contact">
                <Button title="Contact" className="ms-3">
                  Contact
                </Button>
              </Link>
            </div>
          </div>
        </nav>
      </HeaderWrapper>

      <Router />
    </>
  );
};

export default Header;
